import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';
import { MappedTopping, MappedProduct } from 'src/app/pages/new-service/interfaces/Topping';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-modal/confirm-dialog.component';

@Component({
  selector: 'app-modal-extra-washing',
  templateUrl: './modal-extra-washing.component.html',
  styleUrls: ['./modal-extra-washing.component.scss'],
})
export class ModalExtraWashingComponent implements OnInit {
  form: FormGroup;
  tunnelWashProducts: MappedProduct[] = [];
  specializedCenterWashProducts: MappedProduct[] = [];
  selectedProductId: string | null = null;

  constructor(
    private dialogRef: MatDialogRef<ModalExtraWashingComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { summaryData: SummaryData; mappedTopping: MappedTopping },
    private funnelService: FunnelService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    this.initializeWashingOptions();
    this.initializeForm();
  }

  initializeWashingOptions(): void {
    const tunnelWash = this.data.mappedTopping.products.find(
      (product: MappedProduct) => product.id === 'tunnel-wash'
    );
    const specializedWash = this.data.mappedTopping.products.find(
      (product: MappedProduct) => product.id === 'specialized-center-wash'
    );
  
    this.tunnelWashProducts =
      tunnelWash && tunnelWash.relatedProducts ? tunnelWash.relatedProducts : [];
    this.specializedCenterWashProducts =
      specializedWash && specializedWash.relatedProducts ? specializedWash.relatedProducts : [];
  
    // Ordenar specializedCenterWashProducts según el atributo `order`
    this.specializedCenterWashProducts.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  
    this.tunnelWashProducts.forEach((product) => {
      if (!product.titleId) product.titleId = product.productKey;
    });
    this.specializedCenterWashProducts.forEach((product) => {
      if (!product.titleId) product.titleId = product.productKey;
    });
  
    console.log(this.specializedCenterWashProducts);
  }
  

  initializeForm(): void {
    const allProducts = [...this.tunnelWashProducts, ...this.specializedCenterWashProducts];
    allProducts.forEach((product) => {
      const isSelected = this.data.summaryData.toppingInformation?.some(
        (topping: any) => topping.title === product.titleId || topping.title === product.productKey
      );
      this.form.addControl(product.productKey, this.fb.control(isSelected));
      if (isSelected) {
        this.selectedProductId = product.id;
      }
    });
  }

  onClose(): void {
    const confirmationDialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'sure_you_wanna_leave',
        message: 'disclousure_message_confirmation_dialog',
        confirmText: 'confirm',
        cancelText: 'cancel',
      },
    });

    confirmationDialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.closeMainDialog();
      }
    });
  }

  private closeMainDialog(): void {
    this.dialogRef.close({ incompletedTopping: true });
  }

  onToggleChange(productKey: string, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    const product = [...this.tunnelWashProducts, ...this.specializedCenterWashProducts].find(
      (p) => p.productKey === productKey
    );

    if (product) {
      if (isChecked) {
        this.handleProductSelection(product);
      } else {
        this.handleProductDeselection(product);
      }
    }
  }

  handleProductSelection(product: MappedProduct): void {
    this.selectedProductId = product.id;
    this.updateFormControls();
  }

  handleProductDeselection(product: MappedProduct): void {
    this.selectedProductId = null;
    this.updateFormControls();
  }

  updateFormControls(): void {
    [...this.tunnelWashProducts, ...this.specializedCenterWashProducts].forEach((product) => {
      const isSelected = this.selectedProductId === product.id;
      this.form.get(product.productKey)?.setValue(isSelected, { emitEvent: false });
    });
  }

  isTunnelWashProduct(product: MappedProduct): boolean {
    return this.tunnelWashProducts.some((p) => p.productKey === product.productKey);
  }

  isCompleteCarWashTopping(product: MappedProduct): boolean {
    return product.productKey === 'complete-car-wash-topping';
  }

  onAdd(): void {
    if (!this.selectedProductId) {
      this.dialogRef.close({ incompletedTopping: true });
      return;
    }

    this.funnelService
      .addTopping(this.selectedProductId, this.data.summaryData.orderHash!, {}, 'wash')
      .subscribe(
        (response) => {
          this.dialogRef.close({ ...response, selectedProductId: this.selectedProductId });
        },
        (error) => {
          console.error('Error adding topping:', error);
          this.dialogRef.close({ incompletedTopping: true });
        }
      );
  }
}
