import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, from, map, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class TagManagerService {
    private productsUrl = environment.products_url;
    private identityUrl = environment.identity_url;
    private identityEuropeUrl = environment.identity_europe_url;
    private token: string | undefined;

    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthService
    ) {
        this.initializeToken();
    }

    private async initializeToken(): Promise<void> {
        try {
            const token = await this.authService.getAccessToken();
            if (!token) {
                console.error('Unable to obtain access token during initialization');
            }
            this.token = token;
        } catch (error) {
            console.error('Error getting access token during initialization:', error);
        }
    }

    private async ensureToken(): Promise<string> {
        if (!this.token) {
            await this.initializeToken();
        }
        if (!this.token) {
            throw new Error('Unable to obtain access token');
        }
        return this.token;
    }

    private ensureTokenObservable(): Observable<string> {
        return from(this.ensureToken());
    }

    private getHeaders(): Observable<HttpHeaders> {
        return this.ensureTokenObservable().pipe(
            map(
                (token) =>
                    new HttpHeaders({
                        Authorization: `Bearer ${token}`,
                        'Cafler-User-Agent': 'CaflerFleetManager/1.0',
                    })
            )
        );
    }

    getTagNames() {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.get<any>(`${this.identityUrl}/user/me/business`, {
                    headers,
                })
            ),
            catchError((error) => {
                console.error('error:', error);
                throw error;
            })
        );
    }

    createTag(data: { Tag: string }) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.post<any>(`${this.identityEuropeUrl}/user/me/business/tag`, data, {
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error:', error);
                throw error;
            })
        );
    }

    deleteTag(TagId: string) {
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.delete<any>(`${this.identityEuropeUrl}/user/me/business/tag/${TagId}`, {
                    headers,
                })
            ),
            catchError((error) => {
                console.error('Error:', error);
                throw error;
            })
        );
    }

    updateTag(TagId: string, NewTagName: string) {
        const body = { NewTagName };
        console.log('body:', body);
        return this.getHeaders().pipe(
            switchMap((headers) =>
                this.http.patch<any>(`${this.identityEuropeUrl}/user/me/business/tag/${TagId}`, body, {
                    headers,
                })
            ),
            map(response => {
                // console.log('data:', newTagName);
                // console.log('id:', TagId);
                // console.log('response:', response);
                return response;
            }),
            catchError((error) => {
                console.error('Error:', error);
                throw error;
            })
        );
    }


}