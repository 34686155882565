import { ToppingDictionary } from '../interfaces/Topping';

export const myToppingDictionary: ToppingDictionary = {
  'topping-insurance': {
    id: 'insurance',
    icon: 'assets/icons/insurance-during-service.svg',
    titleId: 'insurance',
    descriptionId: 'insuranceDescription',
    showStartingFrom: true,
    hasModal: true,
  },
  'topping-refueling': {
    id: 'refueling',
    icon: 'assets/icons/refuel.svg',
    titleId: 'refueling',
    descriptionId: 'refuelingDescription',
    showStartingFrom: true,
    hasModal: true,
  },
  'topping-replacement-vehicle': {
    id: 'replacement_vehicle',
    icon: 'assets/icons/sustitution-vehicle.svg',
    titleId: 'replacementVehicle',
    descriptionId: 'replacementVehicleDescription',
    showStartingFrom: true,
    hasModal: true,
  },
  'topping-washes': {
    id: 'wash',
    icon: 'assets/icons/lavados-icon.svg',
    titleId: 'wash',
    descriptionId: 'washDescription',
    showStartingFrom: true,
    hasModal: true,
    relatedToppings: {
      'specialized-center-wash': {
        id: 'specialized-center-wash',
        icon: 'assets/icons/lavados-icon.svg',
        titleId: 'specialized-center-wash',
        descriptionId: 'specializedCenterWashDescription',
        showStartingFrom: true,
        relatedToppings: {
          'interior-car-wash-topping': {
            id: 'interior-car-wash-topping',
            icon: '../../../../assets/icons/washing-specialist-center-inside.svg',
            titleId: 'Interior',
            descriptionId:
              'Aspiramos rápidamente el interior de tu vehículo y además, limpiamos tus cristales por dentro para conseguir una limpieza interior completa.',
            showStartingFrom: true,
            order: 1,
          },
          'exterior-car-wash-topping': {
            id: 'exterior-car-wash-topping',
            icon: '../../../../assets/icons/washing-specialist-center-outside.svg',
            titleId: 'Exterior',
            descriptionId:
            'Nos encargamos de realizar una limpieza rápida de carrocería y además, limpiamos exteriormente los cristales para conseguir una limpieza exterior completa.',            
            showStartingFrom: true,
            hasModal: true,
            order: 2,
          },
          'complete-car-wash-topping': {
            id: 'complete-car-wash-topping',
            icon: '../../../../assets/icons/washing-specialist-center-complete.svg',
            titleId: 'Completo',
            descriptionId:
              'Limpiamos rápidamente tu carrocería, aspiramos el interior de tu vehículo y realizamos un limpieza exterior e interior de los cristales para conseguir un lavado completo.',
            showStartingFrom: true,
            hasModal: true,
            order: 3,
          },
          'motorbike-wash-topping': {
            id: 'motorbike-wash-topping',
            icon: 'assets/icons/center-complete.svg',
            titleId: 'Completo',
            descriptionId:
              'Experimenta la limpieza total. Con nuestro servicio completo, tu vehículo recibe un tratamiento integral para un resultado impecable.',
            showStartingFrom: true,
            hasModal: true,
          },
        },
      },
      'tunnel-wash': {
        id: 'tunnel-wash',
        icon: 'assets/icons/lavados-icon.svg',
        titleId: 'tunnel-wash',
        descriptionId: 'handWashDescription',
        showStartingFrom: true,
        relatedToppings: {
          'tunnel-exterior-car-wash-topping': {
            id: 'tunnel-exterior-car-wash-topping',
            icon: 'assets/icons/hand-exterior.svg',
            titleId: 'Lavado exterior en túnel',
            descriptionId:
              'Servicio básico en túnel de gasolinera diseñado para eliminar incrustaciones y suciedad acumulada durante el viaje.',
            showStartingFrom: true,
          },
        },
      },
    },
  },
  'topping-vehicle-data-collection': {
    id: 'checkStatusVehicle',
    icon: 'assets/icons/check-status.svg',
    titleId: 'checkStatusVehicle',
    descriptionId: 'checkStatusVehicleDescription',
    showStartingFrom: true,
    hasModal: false,
  },
  'topping-tyres': {
    id: 'tyres',
    icon: 'assets/icons/tyres.svg',
    titleId: 'checkTyres',
    descriptionId: 'checkTyresDescription',
    showStartingFrom: true,
  },
  'topping-accesory': {
    id: 'accesory',
    icon: 'assets/icons/cafler-fresh.svg',
    titleId: 'caflerFresh',
    descriptionId: 'caflerFreshDescription',
    showStartingFrom: true,
  },
};

export const washToppingDictionary: ToppingDictionary = {};
