import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoaderDatatableService } from 'src/app/core/services/loader-datatable.service';
import { ActiveRoutesService } from 'src/app/pages/active-routes/services/active-routes.service';
import { HomeService } from 'src/app/pages/home/services/home.service';
import { MyServicesService } from 'src/app/pages/my-services/services/my-services.service';
import { Status } from '../status/enum/Status.enum';
import { CancelModalComponent } from './components/cancel-modal/cancel-modal.component';
import { datatableData } from './interfaces/DatatableData.interface';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FunnelDialogComponent } from '../funnel-dialog/funnel-dialog.component';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnInit, OnChanges {
  @ViewChild('contextMenu') contextMenu: ElementRef | undefined;
  @Input() isHomePage = false;
  @Input() isMyServicesToday = false;
  @Input() isMyServicesNext = false;
  @Input() isMyServicesHistory = false;
  @Input() isActiveRoutesDatatable = false;
  @Input() searchValue = '';
  @Input() data: datatableData[] = [];

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  contextMenuVisible = false;
  contextMenuPosition: { top: string; left: string } = { top: '0px', left: '0px' };

  page = 1;
  pageCount = 1;
  limit = 9;
  loading = false;
  selectedOrderHash: string | null = null;

  statusEnum = Status;
  bsModalRef?: BsModalRef;
  showSuccessDeleteServiceAlert = false;

  constructor(
    private homeRequest: HomeService,
    private myServicesRequest: MyServicesService,
    private activeRoutesRequest: ActiveRoutesService,
    private loaderDatatable: LoaderDatatableService,
    private alertService: AlertService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private activeRouteService: ActiveRoutesService
  ) {}

  async ngOnInit() {
    await this.loadData();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['searchValue']) {
      await this.loadData(this.searchValue);
    }
    if (changes['data']) {
      this.data = changes['data'].currentValue;
    }
  }

  private async loadHomePageData() {
    this.limit = 6;
    this.loaderDatatable.show();
    this.loading = true;
    try {
      const data = await this.homeRequest.servicesList().toPromise();
      this.data = this.mapServiceData(data);
    } catch (error) {
      console.error('Error loading home page data', error);
    } finally {
      this.loaderDatatable.hide();
      this.loading = false;
    }
  }

  private async loadMyServicesTodayData(searchValue?: string) {
    this.loaderDatatable.show();
    this.loading = true;
    try {
      let data;
      if (searchValue && searchValue.trim() !== '') {
        data = await this.myServicesRequest.todayServices(this.page, searchValue).toPromise();
      } else {
        data = await this.myServicesRequest.todayServices(this.page).toPromise();
      }
      this.handleServiceDataResponse(data);
    } catch (error) {
      console.error('Error loading today services', error);
    } finally {
      this.loaderDatatable.hide();
      this.loading = false;
    }
    
  }

  private async loadMyServicesHistoryData(searchValue?: string) {
    this.loaderDatatable.show();
    this.loading = true;
    try {
      let data;
      if (searchValue && searchValue.trim() !== '') {
        data = await this.myServicesRequest.historyServices(this.page, searchValue).toPromise();
      } else {
        data = await this.myServicesRequest.historyServices(this.page).toPromise();
      }
      this.handleServiceDataResponse(data);
    } catch (error) {
      console.error('Error loading history services', error);
    } finally {
      this.loaderDatatable.hide();
      this.loading = false;
    }
  }

  private async loadMyServicesNextData(searchValue?: string) {
    this.loaderDatatable.show();
    this.loading = true;
    try {
      let data;
      if (searchValue && searchValue.trim() !== '') {
        data = await this.myServicesRequest.nextServices(this.page, searchValue).toPromise();
      } else {
        data = await this.myServicesRequest.nextServices(this.page).toPromise();
      }
      this.handleServiceDataResponse(data);
    } catch (error) {
      console.error('Error loading next services', error);
    } finally {
      this.loaderDatatable.hide();
      this.loading = false;
    }
  }

  private async loadActiveRoutesList(searchValue?: string) {
    this.loaderDatatable.show();
    this.loading = true;
    try {
      const data = await this.activeRoutesRequest
        .activeRoutesList(this.page, searchValue)
        .toPromise();
      this.handleServiceDataResponse(data);
    } catch (error) {
      console.error('Error loading active routes', error);
    } finally {
      this.loaderDatatable.hide();
      this.loading = false;
    }
  }

  private mapServiceData(data: any) {
    return data.map((service: any) => {
      const serviceDate = new Date(service['ServiceDate']);
      const date = serviceDate.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const day = serviceDate.toLocaleDateString('es-ES', { weekday: 'long' });
      const hour = serviceDate.toLocaleTimeString('es-ES', { hour: 'numeric', minute: 'numeric' });
      return {
        orderHash: service['OrderHash'],
        plateNumber: service['LicensePlate'],
        date,
        day: day,
        hour: hour,
        services: service['ProductTypes'],
        pickUpAddress: service['OriginAddress'],
        returnAddress: service['DestinationAddress'],
        driver: service['DriverName'],
        status: service['OrderStatus'],
        billingName: service['BillingAddress']?.['Name']
      };
    });
  }

  private handleServiceDataResponse(data: any) {
    if (data['Page']) this.page = data['Page'];
    if (data['PageCount']) this.pageCount = data['PageCount'];

    this.data = this.mapServiceData(data['Services']);
  }

  get showDataWithLimit(): datatableData[] {
    return this.data.slice(0, this.limit);
  }

  async loadData(searchValue?: string) {
    switch (true) {
      case this.isHomePage:
        await this.loadHomePageData();
        break;
      case this.isMyServicesToday:
        await this.loadMyServicesTodayData(searchValue);
        break;
      case this.isMyServicesHistory:
        await this.loadMyServicesHistoryData(searchValue);
        break;
      case this.isMyServicesNext:
        await this.loadMyServicesNextData(searchValue);
        break;
      case this.isActiveRoutesDatatable:
        await this.loadActiveRoutesList(searchValue);
        break;
      default:
        this.data = [];
        break;
    }
  }

  setPage(page: number) {
    if (this.page != page) {
      this.page = page;
      this.pageChange.emit(this.page);
      this.loadData(this.searchValue);
    }
  }

  prevPage() {
    if (this.page > 1) {
      this.page--;
      this.pageChange.emit(this.page);
      this.loadData(this.searchValue);
    }
  }

  nextPage() {
    if (this.page < this.pageCount) {
      this.page++;
      this.pageChange.emit(this.page);
      this.loadData(this.searchValue);
    }
  }

  get pages() {
    const totalPages = this.pageCount;
    const visiblePages = [];

    for (let i = 1; i <= Math.min(totalPages, 3); i++) {
      visiblePages.push(i);
    }

    if (totalPages > 3) {
      if (totalPages >= 7) {
        visiblePages.push(-1);
      }
      for (let i = Math.max(totalPages - 2, 1); i <= totalPages; i++) {
        if (!visiblePages.includes(i) && i !== -1) {
          visiblePages.push(i);
        }
      }
    }

    return visiblePages;
  }

  get startIndex() {
    return (this.page - 1) * this.pageCount;
  }

  get endIndex() {
    return Math.min(this.startIndex + this.pageCount - 1, this.page - 1);
  }

  openContextMenu(event: MouseEvent, orderHash: string, isButtonClick = false): void {
    event.preventDefault();
    event.stopPropagation();

    this.closeContextMenu();

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let x: number, y: number;

    if (isButtonClick) {
      const buttonRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
      x = buttonRect.right;
      y = buttonRect.top;
    } else {
      x = event.clientX;
      y = event.clientY;
    }

    if (x + 250 > viewportWidth) {
      x = viewportWidth - 300;
    }

    if (y + 200 > viewportHeight) {
      y = viewportHeight - 200;
    }

    this.contextMenuPosition = {
      top: `${y}px`,
      left: `${x}px`,
    };
    this.selectedOrderHash = orderHash;
    this.contextMenuVisible = true;

    this.changeDetectorRef.detectChanges();
  }

  closeContextMenu(): void {
    this.contextMenuVisible = false;
    this.selectedOrderHash = null;
  }

  downloadService(service: string, status: number): void {
    if (
      Status.Finished === status ||
      Status.Failed === status ||
      Status.CancelledByCafler === status ||
      Status.CancelledByClient === status
    ) {
      this.myServicesRequest.downloadSingleCsv(service).subscribe(
        (data) => {
          if (data.type === 'text/html') {
            const url = URL.createObjectURL(data);
            window.open(url, '_blank');
          } else {
            this.downloadFile(data, `${service}.pdf`);
          }
        },
        (error) => {
          console.error('Error al descargar el archivo', error);
        }
      );
    }
  }

  downloadFile(data: Blob, filename: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  openModal(orderHash: string, status: number): void {
    const initialState = {
      orderHash: orderHash,
      status: status,
    };
    const dialogRef = this.dialog.open(CancelModalComponent, {
      data: initialState,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.showSuccessDeleteServiceAlert = true;
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
  }
  onAlertClosed(): void {
    this.showSuccessDeleteServiceAlert = false;
    this.alertService.setShowAlert(false);
  }

  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent): void {
  //     if (this.contextMenuVisible) {
  //         const target = event.target as HTMLElement;
  //         if (!target.closest('.context-menu') && !target.closest('.dots-menu')) {
  //             this.closeContextMenu();
  //         }
  //     }
  // }
  @HostListener('document:click', ['$event'])
  @HostListener('document:contextmenu', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.contextMenuVisible) {
      const contextMenu = document.querySelector('.context-menu');
      const clickedInsideMenu = contextMenu && contextMenu.contains(event.target as Node);
      const clickedOnDotsButton = (event.target as HTMLElement).closest('.dots-menu');
      if (this.contextMenuVisible && !(event.target as HTMLElement).closest('.context-menu')) {
        this.closeContextMenu();
      }
      if (!clickedInsideMenu && !clickedOnDotsButton) {
        this.closeContextMenu();
      }
    }
  }

  openDetails(event: Event, orderHash: string): void {
    const target = event.target as HTMLElement;
    const interactiveElements = ['button', 'a', 'input', 'select', '.context-menu', '.dots-menu'];

    if (interactiveElements.some((selector) => target.closest(selector))) {
      return;
    }

    if (this.contextMenuVisible) {
      return;
    }
    console.log('OrderHash', orderHash);
    this.router.navigate(['/active-routes', orderHash, 'detail']);
  }

  isTransfer(dataServices: number[]): boolean {
    return dataServices.includes(6) || dataServices.includes(1);
  }

  openFunnelDialog(orderHash: string): void {
    this.activeRouteService.activeRoutesDetails(orderHash).subscribe((data) => {
      const summaryData = this.DTOdatatoReturnTransferSummaryData(data);
      this.dialog.open(FunnelDialogComponent, {
        width: '80%',
        maxWidth: '500px',
        maxHeight: '900px',
        panelClass: 'modal-dialog-centered',
        data: { summaryData, isFromMyServices: true },
      });
    });
  }

  DTOdatatoReturnTransferSummaryData(data: any): Partial<SummaryData> {
    return {
      productInformation: {
        product: {
          productId: data.FinancialInformation.Products[0].ProductId,
        },
        ZoneId: data.StartZoneId,
        FinishZoneId: data.EndZoneId,
      },
      locationInfo: {
        originAddress: data.OriginAddress,
        originAddressDetails: data.OriginAddressDetails,
        originAddressLatitude: data.OriginLatitude,
        originAddressLongitude: data.OriginLongitude,
        originContactName: data.OriginContactName,
        originContactPhoneNumber: data.OriginContactPhoneNumber,
        isOriginContactExternal: data.IsOriginContactExternal,
        destinationAddress: data.DestinationAddress,
        destinationAddressDetails: data.DestinationAddressDetails,
        destinationAddressLatitude: data.DestinationLatitude,
        destinationAddressLongitude: data.DestinationLongitude,
        destinationContactName: data.DestinationContactName,
        destinationContactPhoneNumber: data.DestinationContactPhoneNumber,
        isDestinationContactExternal: data.IsDestinationContactExternal,
      },
      vehicleInformation: {
        brand: data.VehicleInformation.Brand,
        model: data.VehicleInformation.Model,
        licensePlate: data.VehicleInformation.LicensePlate,
        chassisType: data.VehicleInformation.ChassisType,
        fuelType: data.VehicleInformation.FuelType,
      },
    };
  }
}
