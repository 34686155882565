import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Event as NavigationEvent, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as bootstrap from 'bootstrap';
import { filter } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert.service';
import { GetValuesService } from 'src/app/core/services/get-values.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ExtrasService } from 'src/app/pages/new-service/components/new-service-extras/services/extras.service';
import { PersonalizationId } from 'src/app/pages/new-service/components/new-service-personalization/new-service-personalization.component';
import { SummaryData } from 'src/app/pages/new-service/interfaces/SummaryData';
import { FunnelService } from 'src/app/pages/new-service/services/funnel.service';
import { UserService } from 'src/app/services/user.service';
import { NAME_DICTIONARY } from 'src/app/shared/constants/constants';
import { Extra } from 'src/app/shared/interfaces/extra.interface';

export interface ServiceInfo {
  name: string;
  icon: string;
  price?: number;
}

export enum CategoryId {
  transfer = 1,
  mechanicalInspection = 2,
  carWash = 3,
  refueling = 4,
  preTechnicalInspection = 5,
  longDistance = 6,
  technicalInspection = 7,
  valet = 11,
  towTruck = 16,
  whateverYouWant = 17,
  tyres = 12,
}

@Component({
  selector: 'app-billing-summary',
  templateUrl: './billing-summary.component.html',
  styleUrls: ['./billing-summary.component.scss'],
})
export class BillingSummaryComponent implements DoCheck, OnInit {
  @Input() summaryData: SummaryData = {} as SummaryData;
  @Input() returnTransferSummaryData: SummaryData = {} as SummaryData;
  @Input() currentStep = 1;
  @Input() disabledButton = false;
  @Input() selectedSubCategory = false;
  @Input() productSelectionStepForm: FormGroup = new FormGroup({});
  @Output() nextStepEvent = new EventEmitter<void>();
  @Output() summaryDataEvent = new EventEmitter<SummaryData>();
  licensePlateCount = 0;
  moreThanOneLicensePlate = false;
  availableToppingsResponse: any;
  userEmail: any;
  userId: any;
  disabledButtonLastStep = true;
  termsAndConditions = false;
  minimumConditions = false;
  personalizationId = PersonalizationId;
  promotionCode = '';
  promotionCodeValue = 0;
  promotionCodeValidator = false;
  extras: Extra[] = [];
  toppingsTotal = 0;
  caflerFee = 0;
  urgencyFee = 0;
  specificHoursPrice = 0;
  addedNonScheduledMotPrice = 0;
  nameDictionary = NAME_DICTIONARY;
  calculatedIVA = 0;
  private modalInstance: bootstrap.Modal | null = null;
  serviceInfo: { [key: string]: ServiceInfo } = {};

  formattedDateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };

  // Diccionario base de iconos para servicios, el resto ya lo obtenemos de back
  private iconDictionary: { [key: string]: string } = {
    insurance: 'assets/icons/insurance-during-service.svg',
    refill: 'assets/icons/refuel.svg',
    sustitutionVehicle: 'assets/icons/sustitution-vehicle.svg',
    wash: 'assets/icons/lavados-icon.svg',
    checkStatusVehicle: 'assets/icons/check-status.svg',
    tyres: 'assets/icons/tyres.svg',
    accesory: 'assets/icons/cafler-fresh.svg',
    'insurance-car-topping': 'assets/icons/insurance-during-service.svg',
    'insurance-minivan-topping': 'assets/icons/insurance-during-service.svg',
    'insurance-motorbike-topping': 'assets/icons/insurance-during-service.svg',
    'tyre-inflation': 'assets/icons/tyres.svg',
  };
  buttonText = '';

  constructor(
    private getValuesService: GetValuesService,
    private funnelService: FunnelService,
    private userService: UserService,
    private loader: LoaderService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private extrasService: ExtrasService,
    private translate: TranslateService,
    private renderer: Renderer2,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    if (this.summaryData.vehicleInformation?.licensePlate) {
      this.licensePlateCount = this.summaryData.vehicleInformation.licensePlate.length;
      if (this.licensePlateCount > 1) {
        this.moreThanOneLicensePlate = true;
      }
    }
    this.userService.getUserInfo().subscribe((user) => {
      this.userEmail = user.email;
      this.userId = user.id;
    });
    this.generateServiceInfo();
    this.handleCurrentStep();
    this.checkButtonState();
    // Suscribirse a los cambios en activeExtras
    this.extrasService.extrasChanged$.subscribe((extras: Extra[]) => {
      this.extras = extras;
      this.cd.detectChanges(); // Actualizar la vista
    });

    // Cargar el estado del SummaryData desde localStorage
    const savedSummaryData = localStorage.getItem('SummaryData');
    if (savedSummaryData) {
      this.summaryData = JSON.parse(savedSummaryData);
      console.log('DATOS: ', this.summaryData)
    }
    const returnTransferSummaryData = localStorage.getItem('returnTransferSummaryData');
    if (returnTransferSummaryData) {
      this.returnTransferSummaryData = JSON.parse(returnTransferSummaryData);
    }

    if (!this.summaryData.Coupons) {
      this.summaryData.Coupons = [];
      this.summaryDataEvent.emit(this.summaryData);
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEvent) => {
        if (event instanceof NavigationEnd) {
          this.handleNavigation();
        }
      });

    // Ejecuta la lógica inicial
    this.handleNavigation();

    const modalElement = document.getElementById('modalPromotionCode');
    if (modalElement) {
      this.modalInstance = new bootstrap.Modal(modalElement);
    }
  }

  isInspectionPaidHandler(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.summaryData.IsInspectionPaid = inputElement.checked;
    this.saveSummaryData();
    this.cd.detectChanges();
  }

  private saveSummaryData(): void {
    localStorage.setItem('IsInspectionPaid', JSON.stringify(this.summaryData.IsInspectionPaid));
    localStorage.setItem('SummaryData', JSON.stringify(this.summaryData));
  }
  handleCurrentStep(): void {
    switch (this.currentStep) {
      case 1:
      case 2:
      case 3:
      case 4:
        this.buttonText = this.translate.instant('next');
        break;
      case 5:
        this.buttonText = this.translate.instant('next');
        break;
      case 6:
        this.disabledButtonLastStep = !(this.minimumConditions && this.termsAndConditions);
        if (this.returnTransferSummaryData?.orderHash) {
          this.buttonText = this.translate.instant('next');
          break;
        }
        if (this.isOutOfRange()) {
          this.buttonText = this.translate.instant('request_budget');
          break;
        }
        this.buttonText = this.translate.instant('bookService');
        break;
      case 7:
        this.buttonText = this.translate.instant('bookService');
        break;
      default:
        this.buttonText = this.translate.instant('next');
        break;
    }
  }

  isOutOfRange(): boolean {
    return this.summaryData.productInformation.product?.outOfRange ?? false;
  }

  shouldShowCheckoutContainer(): boolean {
    if (this.summaryData?.productInformation?.product) {
      return (
        this.summaryData?.productInformation?.product &&
        this.currentStep !== 3 &&
        this.currentStep !== 2
      );
    } else return false;
  }

  handleButtonClick(): void {
    if (this.currentStep < 5) {
      this.goToCheckOut();
    } else if (this.currentStep === 6) {
      if (this.returnTransferSummaryData.orderHash) {
        this.nextStepEvent.emit();
        return;
      }
      this.finalBook(this.summaryData);
    } else if (this.currentStep === 7 && this.returnTransferSummaryData.orderHash) {
      this.finalBook(this.summaryData);
      this.finalBook(this.returnTransferSummaryData);
    }
  }

  shouldShowCoupon(): boolean {
    if (this.summaryData.Coupons && this.summaryData.Coupons.length > 0) {
      return this.currentStep === 6 && this.summaryData.Coupons[0]?.CouponApplied;
    } else return false;
  }

  private handleNavigation(): void {
    if (this.currentStep === 5) {
      if (!this.isTyre()) {
        this.summaryData.toppingInformation = [];
      }
      this.summaryDataEvent.emit(this.summaryData);
    }
  }

  ngDoCheck(): void {
    this.handleCurrentStep();
    this.checkButtonState();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  private checkButtonState(): void {
    if (this.termsAndConditions && this.minimumConditions) {
      this.disabledButtonLastStep = false;
    } else {
      this.disabledButtonLastStep = true;
    }
  }

  private generateServiceInfo(): void {
    const availableToppings = this.summaryData.availableToppings ?? [];
    for (const toppingCategory of availableToppings) {
      for (const product of toppingCategory.Products) {
        const productKey = product.ProductKey;
        const name = this.nameDictionary[productKey] || productKey;
        const icon = this.getIconForProductKey(productKey);
        this.serviceInfo[product.Id] = {
          name,
          icon,
          price: product.Price,
        };
      }
    }
    // Añadimos los extras activos al diccionario de servicios
    for (const extra of this.extras) {
      const productKey = extra.productKey;
      const name = this.nameDictionary[productKey] || productKey;
      const icon = this.getIconForProductKey(productKey);
      this.serviceInfo[extra.productId] = {
        name,
        icon,
        price: extra.serviceConfiguration?.MonetaryAmount || extra.price,
      };
    }
  }

  getServiceInfo(productId: string): ServiceInfo {
    const serviceInfo = this.serviceInfo[productId];

    if (!serviceInfo || serviceInfo.name === 'Unknown Service') {
      // Verificamos si hay algún extra activo con la palabra "wash" en el productKey
      const extraWash = this.extras.find((extra) => extra.productKey.includes('wash'));
      if (extraWash) {
        return {
          name: this.translate.instant(this.nameDictionary[extraWash.productKey] || 'Wash Service'),
          icon: this.getIconForProductKey(extraWash.productKey),
          price: extraWash.price,
        };
      }
    }

    // Verificamos si el productKey contiene "refill"
    if (serviceInfo && serviceInfo.name === 'Unknown Service' && serviceInfo.price === 0) {
      const refuelExtra = this.extras.find((extra) => extra.productKey.includes('refill'));
      if (refuelExtra) {
        const monetaryAmount = refuelExtra.serviceConfiguration.MonetaryAmount;
        const fuelType = this.summaryData.vehicleInformation.fuelType;
        const icon = this.getIconForProductKey(refuelExtra.productKey);

        return {
          name: fuelType as string,
          icon: icon,
          price: monetaryAmount,
        };
      }
    }

    // Verificamos si el productKey contiene "insurance"
    if (serviceInfo && serviceInfo.name === 'Unknown Service' && serviceInfo.price === 0) {
      const insuranceExtra = this.extras.find((extra) => extra.productKey.includes('insurance'));
      if (insuranceExtra) {
        const icon = 'assets/icons/insurance-during-service.svg';

        return {
          name: this.translate.instant('insurance'),
          icon: icon,
          price: insuranceExtra.serviceConfiguration?.MonetaryAmount || insuranceExtra.price,
        };
      }
    }

    return serviceInfo || { name: 'Unknown Service', icon: 'assets/icons/default-icon.svg' };
  }

  isRefueling(productKey: string): boolean {
    const result = productKey.includes('refill') || productKey.includes('refueling');
    return result;
  }

  isRefuelingProduct(): boolean {
    return this.summaryData.productInformation.product?.ProductKey?.includes('refueling') ?? false;
  }

  hasRefuelingExtra(): boolean {
    return this.extras.some((extra) => {
      const isRefuelingKey =
        extra.productKey.includes('refill') || extra.productKey.includes('refueling');
      return isRefuelingKey;
    });
  }

  getToppingPrice(title: string): number | undefined {
    const topping = this.summaryData.availableToppings?.find(
      (topping: any) => topping.Title === title
    );
    return topping?.Products[0]?.Price;
  }

  getDisplayedTotalValue(): number {
    if (
      this.summaryData.Coupons &&
      this.summaryData.Coupons.length > 0 &&
      this.summaryData.Coupons[0].CouponInformation?.TotalOrderAmount
    ) {
      if (this.summaryData.toppingInformation?.length === 0) {
        return this.summaryData.Coupons[0].CouponInformation.TotalOrderAmount;
      }
    }

    if (this.summaryData.TotalOrderAmount) {
      return this.summaryData.TotalOrderAmount;
    }

    return this.getTotalValue();
  }

  private getNameForProductKey(productKey: string): string {
    if (productKey.includes('refill')) {
      return this.summaryData.vehicleInformation.fuelType as string;
    }
    return this.translate.instant(this.nameDictionary[productKey] || productKey);
  }

  private getIconForProductKey(productKey: string): string {
    if (productKey.includes('wash')) {
      return 'assets/icons/lavados-icon.svg';
    }
    if (productKey.includes('refill') || productKey.includes('refueling')) {
      return 'assets/icons/refuel.svg';
    }
    if (productKey.includes('replacement') || productKey.includes('sustitution')) {
      return 'assets/icons/sustitution-vehicle.svg';
    }
    if (productKey.includes('checklist')) {
      return 'assets/icons/check-status.svg';
    }
    if (productKey.includes('tyre')) {
      return 'assets/icons/tyres.svg';
    }
    return this.iconDictionary[productKey] || 'assets/icons/default-icon.svg';
  }

  getFuelType(id?: number): string {
    if (!id) return 'Desconocido';
    return this.getValuesService.getFuelTypeString(id);
  }

  getChassisType(id?: number): string {
    if (!id) return 'Desconocido';
    return this.getValuesService.getChassisTypeString(id);
  }

  getChassisTypeIcon(id?: number): string {
    if (!id) return 'Desconocido';
    return this.getValuesService.getChassisTypeIcon(id);
  }

  getServiceType(id?: number): string {
    if (!id) return 'Desconocido';
    return this.getValuesService.getServiceTypeString(id);
  }

  getOrderStatus(id?: number): string {
    if (!id) return 'Desconocido';
    return this.getValuesService.getOrderStatusString(id);
  }

  getIva(value?: number): number {
    if (!value) return 0;
    return parseFloat((value * 0.21).toFixed(2));
  }

  addMonetaryAmount(value: number, amount?: number): number {
    if (!amount) return value;
    return value + amount;
  }

  getTotalValue(): number {
    const product = this.summaryData.productInformation?.product;
    const locationInfo = this.summaryData.locationInfo;

    let price = product?.Price ?? 0;
    const monetaryAmount = this.summaryData.serviceConfiguration?.monetaryAmount ?? 0;
    this.urgencyFee = this.summaryData.urgencyFee ? 4 : 0;
    const caflerFee = product?.CaflerFee ?? 0;
    const addedNonScheduledMotPrice = product?.addedNonScheduledMotPrice ?? 0;

    // // Check if the inspection is already paid
    // if (this.summaryData.IsInspectionPaid && product?.ProductKey === this.personalizationId.Itv) {
    //   console.log('ITV is already paid. Proof: ', this.summaryData.IsInspectionPaid);
    //   price = 0;
    //   this.summaryDataEvent.emit(this.summaryData);
    // } else {
    //   if (this.summaryData.productInformation?.product?.ProductKey === this.personalizationId.Itv) {
    //     this.summaryData.productInformation.product.Price = product?.Price;
    //   }
    // }

    let specificHoursPrice = 0;
    if (locationInfo?.isSpecificHours && locationInfo?.fee) {
      specificHoursPrice = locationInfo.fee;
    }

    const toppingsTotal = this.extras.reduce((total, extra) => {
      return total + (extra.price ?? 0);
    }, 0);

    const basePrice =
      price + caflerFee + specificHoursPrice + addedNonScheduledMotPrice + this.urgencyFee;

    //Si hay fueltype ahora mismo, es que el producto es repostaje, asi que quitamos iva.
    const iva = this.summaryData.serviceConfiguration?.fuelType ? 0 : this.getIva(basePrice);

    this.calculatedIVA = iva;
    const promotionCodeValue = this.promotionCodeValue ?? 0;

    const total = basePrice + monetaryAmount + toppingsTotal + iva - promotionCodeValue;

    return parseFloat(total.toFixed(2));
  }
  isOpenForm(): boolean {
    return (
      this.summaryData.productInformation?.product?.ProductKey === this.personalizationId.OpenForm
    );
  }

  goToCheckOut() {
    this.loader.show();
    try {
      this.nextStepEvent.emit();
      if (this.currentStep === 5) {
        this.summaryDataEvent.emit(this.summaryData);
        this.saveSummaryData();
      }
    } finally {
      setTimeout(() => {
        this.loader.hide();
      }, 1000);
    }
  }

  finalBook(summaryData: SummaryData) {
    const storedOrderHashes = JSON.parse(localStorage.getItem('orderHashes') || '[]');
    console.log(storedOrderHashes)
    if (!storedOrderHashes || storedOrderHashes.length === 0) {
      return console.error('No stored order hashes found');
    }
    this.funnelService.finalBook(storedOrderHashes).subscribe((response) => {
      if (response) {
        console.log(`Final book completed for orderHash: ${storedOrderHashes}`);
      }
    });
    this.alertService.setShowAlert(true);
    this.router.navigate(['/services']);
  }

  handleIsExternal(value?: boolean): string {
    return value
      ? this.translate.instant('internal_contact')
      : this.translate.instant('external_contact');
  }

  // Función para parsear una fecha con zona horaria y convertirla a UTC
  private parseDatePart(dateString: string): Date {
    const datePart = dateString.split('T')[0];
    return new Date(datePart);
  }

  // Función para calcular la diferencia en días entre dos fechas
  getValetDays(): number {
    if (!this.summaryData.locationInfo?.serviceStartDate) return 0;
    if (!this.summaryData.locationInfo?.serviceReturnStartDate) return 0;

    const date1 = this.parseDatePart(this.summaryData.locationInfo?.serviceStartDate);
    const date2 = this.parseDatePart(this.summaryData.locationInfo?.serviceReturnStartDate);

    // Calcular la diferencia en milisegundos
    const differenceInMillis = date2.getTime() - date1.getTime();

    // Convertir la diferencia a días
    const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);

    return differenceInDays;
  }

  closeModal() {
    if (this.modalInstance) {
      this.modalInstance.hide();
      // Forzar la eliminación del backdrop
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        this.renderer.removeChild(document.body, backdrop);
      }
      // Eliminar la clase 'modal-open' del body
      this.renderer.removeClass(document.body, 'modal-open');
    }
  }

  // Códigos promocionales
  validatePromotionCode() {
    if (this.summaryData.orderHash && this.promotionCode) {
      this.loader.show();
      this.funnelService
        .validatePromotionCode(this.promotionCode, this.summaryData.orderHash)
        .subscribe(
          (response: any) => {
            if (response.CouponApplied) {
              this.promotionCodeValue = response.CouponInformation.DiscountAmount;
              this.promotionCodeValidator = false;
              this.summaryData.TotalOrderAmount = response.CouponInformation.TotalOrderAmount;
              this.summaryData.Coupons = [response];
              this.summaryDataEvent.emit(this.summaryData);

              // Cerrar el modal
              this.closeModal();
            } else {
              console.error('Promotion code not applied');
              this.promotionCodeValidator = true;
            }
          },
          (error) => {
            console.error('Error validating promotion code:', error);
            this.promotionCodeValidator = true;
          }
        )
        .add(() => {
          this.loader.hide();
        });
    } else {
      console.error('No order hash or promotion code found');
      this.promotionCodeValidator = true;
      this.loader.hide();
    }
  }

  deletePromotionCode(): void {
    this.promotionCode = '';
    this.promotionCodeValue = 0;
    this.promotionCodeValidator = false;

    // Llamada delete a la API para eliminar el código promocional
    if (this.summaryData.orderHash) {
      this.loader.show();
      this.funnelService
        .deletePromotionCode(this.summaryData.orderHash)
        .subscribe((response: any) => {
          if (response) {
            this.summaryData.TotalOrderAmount = response.CouponInformation.TotalOrderAmount;
            this.summaryData.Coupons = [];
            this.summaryData.CouponInformation = undefined;
            this.summaryDataEvent.emit(this.summaryData);
            this.loader.hide();
          }
        });
    }
  }

  handleStartDate(): string {
    if (this.summaryData.locationInfo?.serviceStartDate)
      return this.summaryData.locationInfo?.formattedStartDate as string;
    if (this.summaryData.serviceConfiguration?.appointmentDate)
      return new Date(
        this.summaryData.serviceConfiguration?.appointmentDate as string
      ).toLocaleDateString('es-ES', this.formattedDateOptions);
    else return '';
  }

  handleStartTime(): string {
    if (!this.summaryData.locationInfo?.formattedEndTime) return '';
    if (
      !this.summaryData.serviceConfiguration?.appointmentTime &&
      this.summaryData.locationInfo?.formattedStartTime
    )
      return (
        this.summaryData.locationInfo?.formattedStartTime +
        '-' +
        this.summaryData.locationInfo?.formattedEndTime
      );
    else
      return this.subtractOneHour(this.summaryData.serviceConfiguration?.appointmentHour as string);
  }

  subtractOneHour(timeStr: string) {
    if (!timeStr) return '';
    // Create a Date object with the given time
    const [hours, minutes] = timeStr.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    // Subtract one hour
    date.setHours(date.getHours() - 1);

    // Format back to HH:mm
    const newHours = date.getHours().toString().padStart(2, '0');
    const newMinutes = date.getMinutes().toString().padStart(2, '0');
    return `${newHours}:${newMinutes}`;
  }

  hasRefuelingTopping(): boolean {
    return this.summaryData.toppingInformation?.some((topping: any) =>
      topping.title.includes('refueling')
    ) as boolean;
  }

  getRefuelingToppingPrice(): number {
    const refuelingTopping = this.summaryData.toppingInformation?.find((topping: any) =>
      topping.title.includes('refueling')
    );
    return refuelingTopping ? (refuelingTopping.price as number) : 0;
  }
  isTransit(): boolean {
    if (this.currentStep < 6) return false;
    return (
      this.summaryData.productInformation.product?.ProductKey === this.personalizationId.Transit
    );
  }
  isTyre(): boolean {
    return this.summaryData.productInformation.product?.ProductKey === this.personalizationId.Tyres;
  }
}
