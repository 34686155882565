import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { SupportModalComponent } from '../support-modal/support-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {

  isRotated = false;
  isDropdownOpen = false
  isQuestionOpen = false;
  isSelected: boolean = false; 
  isHovered: boolean = false; 
  private userSubscription!: Subscription;
  userName = 'Test User';
  userEmail = 'test@gmail.com';
  userImg = '../../assets/icons/navbar/nav-user-icon.svg';

  constructor(private router: Router, private userService: UserService, private dialog: MatDialog){}

  ngOnInit(): void {
    this.userSubscription = this.userService.getUserInfo().subscribe((user) => {
      if (user) {
        this.userName = user.name;
        this.userEmail = user.email;
        this.userImg = user.img;
      } else {
        this.userName = 'Guest User';
        this.userEmail = 'No Email Provided';
        this.userImg = '../../assets/icons/navbar/nav-user-icon.svg';
      }
    });

  }

  toggleDropdown(): void {
    if(this.isQuestionOpen){
      this.isQuestionOpen = false;
      this.isSelected = false;
      this.isHovered = false;
    }
    this.isRotated = !this.isRotated;
    this.isDropdownOpen = !this.isDropdownOpen
  }

  toggleQuestion(): void {
    if(this.isRotated && this.isDropdownOpen){
      this.isRotated = false;
      this.isDropdownOpen = false;
    }
    this.isQuestionOpen = !this.isQuestionOpen
  }

  onHover(state: boolean): void {
    this.isHovered = state;
  }

  onSelect(): void {
    this.isSelected = !this.isSelected;
  }

  redirectHelp(){
    const urlHelp = "https://cafler.atlassian.net/wiki/external/ZjI5MWFlZDM2NGIyNDlhODkyNTM0NDFiODE0NmQ2MjA";
    window.open(urlHelp, '_blank');
  }

  redirectTutorials(){
    const urlTutorial = "https://cafler.atlassian.net/wiki/external/NThlZWJiYzNmMzg4NDJlMzgzNzQwMGQzZmI1MDNkYTU";
    window.open(urlTutorial, '_blank');
  }

  redirectSupport(){
    const urlSupport = "";
    window.open(urlSupport, '_blank');
  }

  checkSamePath(subSectionPath: string): boolean {
    const actualPath = this.router.url;
    const segments = actualPath.split('/');
    const lastSegment = segments[segments.length - 1];
    return lastSegment === subSectionPath;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.icon-container') && !targetElement.closest('.dropdown-help')) {
      this.isQuestionOpen = false;
      this.isSelected = false;
    }
  }

  openModal(event: MouseEvent): void {
    this.isQuestionOpen = false;
    this.isHovered = false;
    this.isSelected = false;
    event.stopPropagation();
    const dialogRef = this.dialog.open(SupportModalComponent, {
      width: '80%',
      maxWidth: '800px',
      panelClass: 'modal-dialog-centered',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

}
